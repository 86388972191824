<template>
  <div v-if="configurations?.length > 0" class="summary-configuration">
    <div
      v-for="configuration in configurationPresenter(configurations)"
      :key="configuration.id"
    >
      <p class="summary-configuration__name">
        {{ getLocalizedName(configuration.name) }}:
      </p>
      <div class="summary-configuration__option">
        <Component
          :is="product.isEqual(option.product) ? 'span' : 'ProductLink'"
          v-for="option in configuration.options"
          :key="option.id"
          :product="product.isEqual(option.product) ? null : option.product"
          :class="[{ active: product.isEqual(option.product) }, [option.type]]"
          :style="{ '--color-bg': option.parameters?.hex }"
        >
          {{ "color" !== option.type ? getLocalizedName(option.value) : null }}
        </Component>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
  configurations: { type: [Array, null], required: false },
});

const currentConfiguration = (configurations) => {
  return configurations.find(({ product }) => {
    return props.product.isEqual(product);
  });
};

const configurationPresenter = (configurations) => {
  const configurationPresenter = {};

  configurations.forEach((configuration) => {
    configuration.dimensions.forEach((dimension) => {
      if (!configurationPresenter.hasOwnProperty(dimension.id)) {
        configurationPresenter[dimension.id] = {
          id: dimension.id,
          name: dimension.name,
          options: {},
        };
      }

      if (
        !configurationPresenter[dimension.id].options.hasOwnProperty(
          dimension.option.id,
        )
      ) {
        const getOptionProduct = configurations.find((configuration) =>
          configuration.dimensions.every((configurationDimension) => {
            if (configurationDimension.id === dimension.id) {
              return configurationDimension.option.id === dimension.option.id;
            }

            return (
              currentConfiguration(configurations).dimensions.find(
                (currentDimension) =>
                  currentDimension.id === configurationDimension.id,
              ).option?.id === configurationDimension.option.id
            );
          }),
        )?.product;

        if (undefined !== getOptionProduct) {
          configurationPresenter[dimension.id].options[dimension.option.id] = {
            ...dimension.option,
            product: new Product(getOptionProduct),
          };
        }
      }
    });
  });

  return configurationPresenter;
};
</script>

<style lang="scss" scoped>
.summary-configuration {
  @include flex-container(row, flex-start, center);
  flex-wrap: wrap;
  gap: 24px;

  border-top: 1px solid var(--color-sky-base);

  padding-top: 24px;

  @include mobile {
    @include flex-container(column, flex-start, flex-start);

    padding-top: 16px;
  }

  &__name {
    @include font(14, 18);

    margin-bottom: 8px;
  }

  &__option {
    @include flex-container(row, flex-start, center);
    gap: 16px;

    & .regular {
      cursor: pointer;

      @include font(12, 16);

      border: 1px solid var(--color-sky-base);
      border-radius: 30px;

      padding: 4px 8px;

      transition:
        background-color 0.2s,
        border-color 0.2s;

      @include mobile {
        padding: 8px 16px;
      }

      &:not(.active):hover {
        background-color: var(--color-primary-lightest);
        border-color: var(--color-primary-light);
      }

      &.active {
        color: white;
        background-color: var(--color-primary-base);
        border: var(--color-primary-base);
      }
    }

    & .color {
      --color-bg: white;

      cursor: pointer;
      position: relative;
      z-index: 1;

      width: 22px;
      height: 22px;

      background-color: var(--color-bg);
      border-radius: 50%;
      border: 1px solid var(--color-sky-base);

      transition: border-color 0.2s;

      &:not(.active):hover {
        border-color: var(--color-primary-light);
      }

      &.active {
        &::after {
          content: "";
          position: absolute;
          z-index: 10;

          top: -3px;
          left: -3px;

          width: 26px;
          height: 26px;

          border-radius: 50%;
          border: 1px solid var(--color-primary-base);
        }
      }
    }
  }
}
</style>
